import * as React from "react"
import "./hotels.scss"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Hotels from "./components/listings"



const HotelListings = () => (
  <Layout>
    <Seo title="Royal Palm Destinations | Royal Palm Destinations Hotels " />
    <Hotels/>
  </Layout>
)

export default HotelListings;
